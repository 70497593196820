.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 900000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-pane {
  display: flex;
  position: absolute;
  z-index: 900000;
}

.cdk-overlay-pane {
  pointer-events: auto;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 900000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@-webkit-keyframes cdk-text-field-autofill-start {
  /*!*/
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}

@-webkit-keyframes cdk-text-field-autofill-end {
  /*!*/
}

@keyframes cdk-text-field-autofill-end {
  /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  -webkit-animation: cdk-text-field-autofill-start 0s 1ms;
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  -webkit-animation: cdk-text-field-autofill-end 0s 1ms;
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important;
}

.mat-select-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.mat-select-panel {
  background: #fff;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  font-size: inherit;
  line-height: 3em;
  height: 3em;
}

.mat-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 48px;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.mat-menu-panel {
  background: #fff;
}

.mat-elevation-z4 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}

fa-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

/*calendar*/
.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled
  > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
  background-color: #005f9e;
  color: #fff;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #005f9e;
  color: #fff;
}

.mat-datepicker-content.mat-accent
  .mat-calendar-body-disabled
  > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}

.mat-datepicker-content.mat-accent
  .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: #fff;
}

.mat-datepicker-content.mat-warn
  .mat-calendar-body-disabled
  > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}

.mat-datepicker-content.mat-warn
  .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #005f9e;
}

.mat-datepicker-toggle-active.mat-accent {
  color: #005f9e;
}

.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
