/* You can add global styles to this file, and also import other style files */

@import url("https://cdn.jsdelivr.net/npm/@trimble-oss/modus-icons@1/dist/modus-solid/fonts/modus-icons.css");

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #f9f9fb;
}

.main-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;

  .header-container {
    min-height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    z-index: 2;
  }

  .body-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: #f9f9fb;

    .side-nav-container {
      min-width: 56px;
      width: 56px;
      z-index: 101;
    }

    .module-container {
      flex-grow: 1;
      overflow: hidden;
    }

    .error-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;

      .material-icons-red {
        font-size: 35px;
      }

      .error-text-one {
        font-weight: 600;
        font-size: 25px;
        color: #484756;
      }

      .error-text-two {
        color: #9a99a2;
      }
    }
  }
}

.resizable-dialog .mat-dialog-container {
  position: relative;
  overflow: auto;
}

@media (max-width: 575px) {
  .body-container {
    .side-nav-container {
      width: 0px !important;
      min-width: 0px !important;
    }
  }
}
